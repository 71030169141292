@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

:root {
  /* --font-base: "DM Sans", sans-serif; */

  --primary-color: #FAF4F2;
  --secondary-color: #313bac;
  --black-color: #F47121;
  --lightGray-color: #e4e4e4;
  --gray-color: #14023b;
  --brown-color: #46364a;
  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
