@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

#home {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;

  // disable the default padding styles
  .app__wrapper {
    padding: 0;

    .copyright {
      display: none;
    }
  }
}

.app__header {
  flex: 1;
  width: 100%;
  height: 100vh;
  background: url("../../assets/background.png");
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
}

.app__header-info {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  margin-top: 30%;

  @media screen and (max-width: 480px) {
    height: 100%;
    margin-top: 60%;
    margin-right: 5vw;
  }
  @media screen and (max-width: 768px) and (min-width: 480px) {
    height: 100%;
    margin-top: 60%;
    margin-right: 5vw;
  }

  @media screen and (max-width: 1024px) and (min-width: 768px) {
    height: 100%;
    margin-top: 60%;
    margin-right: 5vw;
  }
  @media screen and (max-width: 1200px) and (min-width: 1024px) {
    height: 100%;
    margin-top: 40%;
    margin-right: 5vw;
  }
  @media screen and (min-width: 1200px) {
    height: 100%;
    margin-top: 40%;
    margin-right: 5vw;
  }
}

.app__header-badge {

  .head-text-title {
    font-family: "Lora", serif;
    font-size: 30px;
    text-align: right;
    color: #ffffff;
    letter-spacing: .7px;
    
    @media screen and (max-width: 480px) {
      font-size: 28px;
    }
    @media screen and (max-width: 768px) and (min-width: 480px) {
      font-size: 30px;
    }

    @media screen and (max-width: 1024px) and (min-width: 768px) {
      font-size: 32px;
    }
    @media screen and (max-width: 1200px) and (min-width: 1024px) {
      font-size: 38px;
    }
    @media screen and (min-width: 1200px) {
      font-size: 42px;
    }
  }

  .p-text-subtitle {
    font-family: "Lora", serif;
    font-size: 30px;
    color: #ffffff;
    text-align: right;
    letter-spacing: .7px;

    @media screen and (max-width: 480px) {
      font-size: 21px;
    }
    @media screen and (max-width: 768px) and (min-width: 480px) {
      font-size: 25px;
    }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
      font-size: 29px;
    }
    @media screen and (max-width: 1200px) and (min-width: 1024px) {
      font-size: 30px;
    }
    @media screen and (min-width: 1200px) {
      font-size: 32px;
    }

  }

  .about-header {
    font-family: 'Nunito', sans-serif;    
    font-size: 18px;
    text-align: justify;
    font-weight: 500;
    color: "#ffffff";
    padding-left: 20%;
    

    @media screen and (max-width: 480px) {
      font-size: 16px;
      line-height: 25px;
      letter-spacing: .5px;
      padding-left: 24%;
      word-spacing: 0px;
    }
    @media screen and (max-width: 768px) and (min-width: 480px) {
      font-size: 18px;
      line-height: 25px;
      letter-spacing: .7px;
      padding-left: 30%;
      word-spacing: .2px;
    }

    @media screen and (max-width: 1024px) and (min-width: 768px) {
      font-size: 20px;
      line-height: 25px;
      letter-spacing: .7px;
      padding-left: 30%;
      word-spacing: 0px;
    }
    @media screen and (max-width: 1200px) and (min-width: 1024px) {
      font-size: 21px;
      line-height: 25px;
      letter-spacing: .7px;
      padding-left: 30%;
      word-spacing: 0px;
    }
    @media screen and (min-width: 1200px) {
      font-size: 22px;
      line-height: 25px;
      letter-spacing: .7px;
      padding-left: 40%;
      word-spacing: 0px;
    }
  }
}
