@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "react-image-gallery/styles/css/image-gallery.css";

.app__service {
  flex: 1;
  width: 100%;
}

.service-container {
  background: rgb(255, 255, 255);
  flex: 1;
  display: flex;
  flex-direction: column;

  .motto-services {
    width: 100%;
    background: rgb(0, 0, 0);

    .motto-text-services {
      font-size: 1.9rem;
      font-weight: 800;
      text-align: center;
      color: #ffffff;
      padding-top: 5vh;
      padding-bottom: 5vh;

      @media screen and (max-width: 480px) {
        font-family: "Lora", serif;
        letter-spacing: 2px;
        font-size: 21px;
        padding-left: 4vw;
        padding-right: 4vw;
      }
      @media screen and (max-width: 768px) and (min-width: 480px) {
        font-family: "Lora", serif;
        letter-spacing: 2px;
        font-size: 25px;
        padding-left: 4vw;
        padding-right: 4vw;
      }

      @media screen and (max-width: 1024px) and (min-width: 768px) {
        font-family: "Lora", serif;
        letter-spacing: 2px;
        font-size: 29px;
        padding-left: 4vw;
        padding-right: 4vw;
      }
      @media screen and (max-width: 1200px) and (min-width: 1024px) {
        font-family: "Lora", serif;
        letter-spacing: 2px;
        font-size: 32px;
        padding-left: 4vw;
        padding-right: 4vw;
      }
      @media screen and (min-width: 1200px) {
        font-family: "Lora", serif;
        letter-spacing: 2px;
        font-size: 35px;
        padding-left: 4vw;
        padding-right: 4vw;
      }
    }
  }
}

.service-main-container {
  @media only screen and (max-width: 480px) {
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    padding-right: 4vw;
    padding-left: 4vw;
  }
  @media screen and (max-width: 768px) and (min-width: 480px) {
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    padding-right: 4vw;
    padding-left: 4vw;
  }

  @media screen and (max-width: 1024px) and (min-width: 768px) {
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    padding-right: 4vw;
    padding-left: 4vw;
  }
  @media screen and (max-width: 1200px) and (min-width: 1024px) {
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    padding-right: 4vw;
    padding-left: 4vw;
  }
  @media screen and (min-width: 1200px) {
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    padding-right: 4vw;
    padding-left: 4vw;
  }

  .head-text-service {
    font-family: "Lora", serif;
    letter-spacing: 2px;
    font-size: 21px;
    color: #f47121;
    font-weight: 900;

    @media only screen and (max-width: 480px) {
      font-weight: 900;
      font-size: 21px;
      letter-spacing: 2px;
    }
    @media screen and (max-width: 768px) and (min-width: 480px) {
      font-weight: 900;
      font-size: 25px;
      letter-spacing: 2px;
    }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
      font-weight: 900;
      font-size: 29px;
      letter-spacing: 2px;
    }
    @media screen and (max-width: 1200px) and (min-width: 1024px) {
      font-weight: 900;
      font-size: 32px;
      letter-spacing: 2px;
    }
    @media screen and (min-width: 1200px) {
      font-weight: 900;
      font-size: 35px;
      letter-spacing: 2px;
    }
  }

  .service-def {
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    text-align: justify;
    color: #000000;
    @media screen and (max-width: 768px) and (min-width: 480px) {
      font-size: 18px;
    }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
      font-size: 20px;
    }
    @media screen and (max-width: 1200px) and (min-width: 1024px) {
      font-size: 21px;
    }
    @media screen and (min-width: 1200px) {
      font-size: 22px;
    }
  }

}

.heading-services-sub {

  background: url("../../assets/serviceBackground.png");
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;

  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: 768px) and (min-width: 600px) {
    display: flex;
    flex-direction: row;
  }

  @media screen and (max-width: 1024px) and (min-width: 768px) {
    display: flex;
    flex-direction: row;
  }
  @media screen and (max-width: 1200px) and (min-width: 1024px) {
    display: flex;
    flex-direction: row;
  }
  @media screen and (min-width: 1200px) {
    display: flex;
    flex-direction: row;
  }
  

  .main-item-services-1 {
    margin-top: 20px;
    margin-bottom: 20px;

    @media only screen and (max-width: 480px) {
      padding-right: 4vw;
      padding-left: 4vw;
    }
    @media screen and (max-width: 768px) and (min-width: 480px) {
      padding-right: 4vw;
      padding-left: 4vw;
    }

    @media screen and (max-width: 1024px) and (min-width: 768px) {
      padding-right: 4vw;
      padding-left: 4vw;
    }
    @media screen and (max-width: 1200px) and (min-width: 1024px) {
      padding-right: 4vw;
      padding-left: 4vw;
    }
    @media screen and (min-width: 1200px) {
      padding-right: 4vw;
      padding-left: 4vw;
    }
  }

  .main-item-services-2 {
    margin-top: 20px;
    margin-bottom: 20px;
  
    @media only screen and (max-width: 480px) {
      padding-right: 4vw;
      padding-left: 4vw;
    }
    @media screen and (max-width: 768px) and (min-width: 480px) {
      padding-right: 4vw;
      padding-left: 4vw;
    }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
      padding-right: 4vw;
      padding-left: 4vw;
    }
    @media screen and (max-width: 1200px) and (min-width: 1024px) {
      padding-right: 4vw;
      padding-left: 10vw;
    }
    @media screen and (min-width: 1200px) {
      padding-right: 4vw;
      padding-left: 18vw;
    }
  }

  .heading {
    font-family: "Lora", serif;
    letter-spacing: 2px;
    font-size: 21px;
    color: #f47121;
    font-weight: 900;

    @media only screen and (max-width: 480px) {
      font-weight: 900;
      font-size: 18px;
      letter-spacing: 2px;
    }
    @media screen and (max-width: 768px) and (min-width: 480px) {
      font-weight: 900;
      font-size: 21px;
      letter-spacing: 2px;
    }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
      font-weight: 900;
      font-size: 22px;
      letter-spacing: 2px;
    }
    @media screen and (max-width: 1200px) and (min-width: 1024px) {
      font-weight: 900;
      font-size: 23px;
      letter-spacing: 2px;
    }
    @media screen and (min-width: 1200px) {
      font-weight: 900;
      font-size: 25px;
      letter-spacing: 2px;
    }
  }

  .services-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;

    .service-us-arrow {
      width: 25px;
      height: 30px;
      margin-right: 20px;

      @media only screen and (max-width: 480px) {
        width: 25px;
        height: 30px;
        margin-right: 15px;
      }
      @media screen and (max-width: 768px) and (min-width: 480px) {
        width: 25px;
        height: 30px;
        margin-right: 15px;
      }
      @media screen and (max-width: 1024px) and (min-width: 768px) {
        width: 25px;
        height: 30px;
        margin-right: 15px;
      }
      @media screen and (max-width: 1200px) and (min-width: 1024px) {
        width: 25px;
        height: 30px;
        margin-right: 15px;
      }
      @media screen and (min-width: 1200px) {
        width: 25px;
        height: 30px;
        margin-right: 15px;
      }
    }

    .service-item {
      margin-top: 10px;
      margin-bottom: 8px;
      font-family: "Nunito", sans-serif;

      @media only screen and (max-width: 480px) {
        font-size: 16px;
        font-weight: 500;
        word-spacing: 0.2px;
      }
      @media screen and (max-width: 768px) and (min-width: 480px) {
        font-size: 18px;
        font-weight: 500;
        word-spacing: 0.2px;
      }
      @media screen and (max-width: 1024px) and (min-width: 768px) {
        font-size: 20px;
        font-weight: 500;
        word-spacing: 0.2px;
      }
      @media screen and (max-width: 1200px) and (min-width: 1024px) {
        font-size: 21px;
        font-weight: 500;
        word-spacing: 0.2px;
      }
      @media screen and (min-width: 1200px) {
        font-size: 25px;
        font-weight: 500;
        word-spacing: 0.2px;
      }
    }
  }
}

