@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "react-image-gallery/styles/css/image-gallery.css";

.app__about {
  flex: 1;
  width: 100%;
}

.about-container {
  background: rgb(255, 255, 255);
  flex: 1;

  .motto-about {
    width: 100%;
    background: rgb(0, 0, 0);

    .motto-text {
      font-size: 1.9rem;
      font-weight: 800;
      text-align: center;
      color: #ffffff;
      padding-top: 5vh;
      padding-bottom: 5vh;

      @media screen and (max-width: 480px) {
        font-family: "Lora", serif;
        letter-spacing: 2px;
        font-size: 21px;
        padding-left: 4vw;
        padding-right: 4vw;
      }
      @media screen and (max-width: 768px) and (min-width: 480px) {
        font-family: "Lora", serif;
        letter-spacing: 2px;
        font-size: 25px;
        padding-left: 4vw;
        padding-right: 4vw;
      }
      @media screen and (max-width: 1024px) and (min-width: 768px) {
        font-family: "Lora", serif;
        letter-spacing: 2px;
        font-size: 29px;
        padding-left: 4vw;
        padding-right: 4vw;
      }
      @media screen and (max-width: 1200px) and (min-width: 1024px) {
        font-family: "Lora", serif;
        letter-spacing: 2px;
        font-size: 32px;
        padding-left: 4vw;
        padding-right: 4vw;
      }
      @media screen and (min-width: 1200px) {
        font-family: "Lora", serif;
        letter-spacing: 2px;
        font-size: 32px;
        padding-left: 4vw;
        padding-right: 4vw;
      }
    }
  }
}

.mobile-only {
  @media only screen and (max-width: 480px) {
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    padding-right: 4vw;
    padding-left: 4vw;
  }
  @media screen and (max-width: 768px) and (min-width: 480px) {
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    padding-right: 4vw;
    padding-left: 4vw;
  }
  .heading-mobile-only {
    font-family: "Lora", serif;
    letter-spacing: 2px;
    font-size: 21px;
    color: #f47121;
    font-weight: 900;

    @media only screen and (max-width: 480px) {
      font-weight: 900;
      font-size: 21px;
      letter-spacing: 2px;
    }
    @media screen and (max-width: 768px) and (min-width: 480px) {
      font-weight: 900;
      font-size: 25px;
      letter-spacing: 2px;
    }
  }

  .about-us-mobile-only {
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    text-align: justify;
    color: #000000;

    @media only screen and (max-width: 768px) {
      font-size: 18px;
    }
  }

  @media only screen and (min-width: 769px) {
    display: none;
  }
}

.head-text-subheading-about-us {
  display: flex;
  flex-direction: row;
  background: url("../../assets/aboutBackground.png");
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;

  @media screen and (max-width: 1024px) and (min-width: 769px) {
    padding-right: 4vw;
    padding-left: 4vw;
  }
  @media screen and (max-width: 1200px) and (min-width: 1024px) {
    padding-right: 4vw;
    padding-left: 4vw;
  }
  @media screen and (min-width: 1200px) {
    padding-right: 4vw;
    padding-left: 4vw;
  }

  .web-only {
    @media only screen and (max-width: 480px) {
      margin-top: 20px;
      align-items: center;
      justify-content: center;
      padding-right: 4vw;
      padding-left: 4vw;
    }
    @media screen and (max-width: 768px) and (min-width: 480px) {
      margin-top: 20px;
      align-items: center;
      justify-content: center;
      padding-right: 4vw;
      padding-left: 4vw;
    }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
      margin-top: 20px;
      align-items: center;
      justify-content: center;
    }
    @media screen and (max-width: 1200px) and (min-width: 1024px) {
      margin-top: 20px;
      align-items: center;
      justify-content: center;
    }
    @media screen and (min-width: 1200px) {
      margin-top: 20px;
      align-items: center;
      justify-content: center;
    }
    .heading-web-only {
      font-family: "Lora", serif;
      letter-spacing: 2px;
      font-size: 21px;
      color: #f47121;
      font-weight: 900;
  
      @media only screen and (max-width: 480px) {
        font-weight: 900;
        font-size: 21px;
        letter-spacing: 2px;
      }
      @media screen and (max-width: 768px) and (min-width: 480px) {
        font-weight: 900;
        font-size: 25px;
        letter-spacing: 2px;
      }

      @media screen and (max-width: 1024px) and (min-width: 768px) {
        font-weight: 900;
        font-size: 29px;
        letter-spacing: 2px;
      }
      @media screen and (max-width: 1200px) and (min-width: 1024px) {
        font-weight: 900;
        font-size: 32px;
        letter-spacing: 2px;
      }
      @media screen and (min-width: 1200px) {
        font-weight: 900;
        font-size: 34px;
        letter-spacing: 2px;
      }
    }
  
    .about-us-web-only {
      font-family: "Nunito", sans-serif;
      font-size: 16px;
      text-align: justify;
      color: #000000;
  
      @media only screen and (max-width: 768px) {
        font-size: 18px;
      }
      @media screen and (max-width: 1024px) and (min-width: 768px) {
        font-size: 20px;
      }
      @media screen and (max-width: 1200px) and (min-width: 1024px) {
        font-size: 21px;
      }
      @media screen and (min-width: 1200px) {
        font-size: 22px;
      }
    }
  
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  .main-item {
    margin-top: 20px;
    margin-bottom: 20px;
    @media only screen and (max-width: 480px) {
      padding-right: 4vw;
      padding-left: 4vw;
    }
    @media screen and (max-width: 768px) and (min-width: 480px) {
      padding-right: 4vw;
      padding-left: 4vw;
    }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
      padding-left: 4vw;
      padding-right: 4vw;
    }
    @media screen and (max-width: 1200px) and (min-width: 1024px) {
      padding-left: 4vw;
      padding-right: 4vw;
    }
    @media screen and (min-width: 1200px) {
      padding-left: 4vw;
      padding-right: 4vw;
    }

  }

  .heading {
    font-family: "Lora", serif;
    letter-spacing: 2px;
    font-size: 21px;
    color: #f47121;
    font-weight: 900;

    @media only screen and (max-width: 480px) {
      font-weight: 900;
      font-size: 18px;
      letter-spacing: 2px;
    }
    @media screen and (max-width: 768px) and (min-width: 480px) {
      font-weight: 900;
      font-size: 21px;
      letter-spacing: 2px;
    }

    @media screen and (max-width: 1024px) and (min-width: 768px) {
      font-weight: 900;
      font-size: 22px;
      letter-spacing: 2px;
      padding-right: 20vw;
    }
    @media screen and (max-width: 1200px) and (min-width: 1024px) {
      font-weight: 900;
      font-size: 23px;
      letter-spacing: 2px;
      padding-right: 20vw;
    }
    @media screen and (min-width: 1200px) {
      font-weight: 900;
      font-size: 25px;
      letter-spacing: 2px;
      padding-right: 20vw;
    }
  }

  .about-us-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;

    .about-us-arrow {
      width: 25px;
      height: 30px;
      margin-right: 20px;
      @media only screen and (max-width: 480px) {
        width: 25px;
        height: 30px;
        margin-right: 15px;
      }
      @media screen and (max-width: 768px) and (min-width: 480px) {
        width: 25px;
        height: 30px;
        margin-right: 15px;
      }
      @media screen and (max-width: 1024px) and (min-width: 768px) {
        width: 25px;
        height: 30px;
        margin-right: 15px;
      }
      @media screen and (max-width: 1200px) and (min-width: 1024px) {
        width: 25px;
        height: 30px;
        margin-right: 15px;
      }
      @media screen and (min-width: 1200px) {
        width: 25px;
        height: 30px;
        margin-right: 15px;
      }
    }
    .about-us-item {
      margin-top: 10px;
      margin-bottom: 8px;
      font-family: "Nunito", sans-serif;

      @media only screen and (max-width: 480px) {
        font-size: 16px;
        font-weight: 500;
        word-spacing: 0.2px;
      }
      @media screen and (max-width: 768px) and (min-width: 480px) {
        font-size: 18px;
        font-weight: 500;
        word-spacing: 0.2px;
      }
      @media screen and (max-width: 1200px) and (min-width: 1024px) {
        font-size: 20px;
        font-weight: 500;
        word-spacing: 0.2px;
      }
      @media screen and (min-width: 1200px) {
        font-size: 24px;
        font-weight: 500;
        word-spacing: 0.2px;
      }
      font-size: 20px;
        font-weight: 500;
        word-spacing: 0.2px;
    }
  }
}

.underline-1 {
  width: 40%;
  height: 2.5px;
  background-color: var(--black-color);
  margin-bottom: 10px;
  margin-top: 4px;

  @media screen and (min-width: 2000px) {
    display: none;
  }
}

.underline-2 {
  width: 30%;
  height: 2.5px;
  background-color: var(--black-color);

  @media screen and (min-width: 2000px) {
    display: none;
  }
}
.underline-3 {
  width: 15%;
  height: 2.5px;
  background-color: var(--black-color);
  margin-bottom: 10px;
  margin-top: 4px;

  @media screen and (min-width: 2000px) {
    display: none;
  }
}

.underline-4 {
  width: 10%;
  height: 2.5px;
  background-color: var(--black-color);

  @media screen and (min-width: 2000px) {
    display: none;
  }
}

.image-video-container {
  background-color: #000000;
  padding-bottom: 30px;
  padding-top: 30px;
  padding-right: 4vw;
  padding-left: 4vw;

  .heading-recent-works {
    font-family: "Lora", serif;
    letter-spacing: 2px;
    font-size: 21px;
    color: #f47121;
    font-weight: 900;

    @media only screen and (max-width: 480px) {
      font-weight: 900;
      font-size: 18px;
      letter-spacing: 2px;
    }
    @media screen and (max-width: 768px) and (min-width: 480px) {
      font-weight: 900;
      font-size: 21px;
      letter-spacing: 2px;
    }

    @media screen and (max-width: 1024px) and (min-width: 768px) {
      font-weight: 900;
      font-size: 22px;
      letter-spacing: 2px;
    }
    @media screen and (max-width: 1200px) and (min-width: 1024px) {
      font-weight: 900;
      font-size: 23px;
      letter-spacing: 2px;
    }
    @media screen and (min-width: 1200px) {
      font-weight: 900;
      font-size: 25px;
      letter-spacing: 2px;
    }
  }

  .app__profiles {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 20px;

    .app__profile-button {
      background-color: #f47121;
      border: none;
      color: white;
      padding: 16px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 4px 4px;
      transition-duration: 0.4s;
      cursor: pointer;
      border-radius: 20px;
    }

    .app__profile-button:hover {
      background-color: #206761;
      color: white;
    }

    .app__profile-button:focus {
      background-color: #206761;
      color: white;
    }
  }

  .our-works-image {
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .our-works-videos {
    padding-top: 20px;
    padding-bottom: 40px;
    .video-container {
      width: 100%;
      .video {
        @media only screen and (max-width: 480px) {
          // width: 100%;
        }
      }
    }
  }
}
