.footer-container {
  background: #000000;
  padding-top: 30px;
  padding-bottom: 10px;
  padding-right: 4vw;
  padding-left: 4vw;

  .heading {
    font-family: "Lora", serif;
    letter-spacing: 2px;
    font-size: 21px;
    color: #f47121;
    font-weight: 900;

    @media only screen and (max-width: 480px) {
      font-weight: 900;
      font-size: 21px;
      letter-spacing: 2px;
    }

    @media screen and (max-width: 768px) and (min-width: 480px) {
      font-weight: 900;
      font-size: 25px;
      letter-spacing: 2px;
    }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
      font-weight: 900;
      font-size: 29px;
      letter-spacing: 2px;
    }
    @media screen and (max-width: 1200px) and (min-width: 1024px) {
      font-weight: 900;
      font-size: 32px;
      letter-spacing: 2px;
    }
    @media screen and (min-width: 1200px) {
      font-weight: 900;
      font-size: 35px;
      letter-spacing: 2px;
    }
  }
}

.app__footer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: 768px) and (min-width: 480px) {
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: 1024px) and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  @media screen and (max-width: 1200px) and (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

  }
  @media screen and (min-width: 1200px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .app__footer {
    flex: 1;
    flex-direction: row;
    margin-top: 20px;

    @media screen and (max-width: 480px) {
      flex-direction: column;
      align-items: center;
    }
    @media screen and (max-width: 768px) and (min-width: 480px) {
      flex-direction: column;
      align-items: center;
    }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
      margin-right: 20px;
    }
    @media screen and (max-width: 1200px) and (min-width: 1024px) {
      margin-right: 30px;
    }
    @media screen and (min-width: 1200px) {
      margin-right: 30px;
    }

    .app__footer-item-1 {
      border-radius: 15px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: #ffffff;
      margin-bottom: 20px;

      @media screen and (max-width: 480px) {
        width: 90vw;
      }
      @media screen and (max-width: 768px) and (min-width: 480px) {
        width: 90vw;
      }
      @media screen and (max-width: 1024px) and (min-width: 768px) {
        width: 50vw;
      }
      @media screen and (max-width: 1200px) and (min-width: 1024px) {
        width: 60vw;
      }
      @media screen and (min-width: 1200px) {
        width: 50vw;
      }

      .location-text {
        font-family: "Nunito", sans-serif;
        font-size: 16px;
        text-align: center;
        color: #ffffff;
        margin-top: 10px;

        @media only screen and (max-width: 480px) {
          font-size: 16px;
          text-align: center;
        }
        @media screen and (max-width: 768px) and (min-width: 480px) {
          font-size: 18px;
          text-align: center;
        }
        @media screen and (max-width: 1024px) and (min-width: 768px) {
          font-size: 20px;
          text-align: center;
        }
        @media screen and (max-width: 1200px) and (min-width: 1024px) {
          font-size: 21px;
          text-align: center;
          margin-top: 8px;
        }
        @media screen and (min-width: 1200px) {
          font-size: 23px;
          text-align: center;
          margin-top: 8px;
        }
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 15px;
        object-fit: cover;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }
}

.app__footer-cards {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1024px) and (min-width: 768px) {
    margin-top: 40px;
  }
  @media screen and (max-width: 1200px) and (min-width: 1024px) {
    margin-top: 40px;
  }
  @media screen and (min-width: 1200px) {
    margin-top: 40px;
  }
  .app__footer-card {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    background-color: #fef4f5;

    transition: all 0.3s ease-in-out;

   

    @media only screen and (max-width: 480px) {
      width: 90vw;
    }
    @media screen and (max-width: 768px) and (min-width: 480px) {
      width: 90vw;
    }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
      width: 40vw;
    }
    @media screen and (max-width: 1200px) and (min-width: 1024px) {
      width: 30vw;
    }
    @media screen and (min-width: 1200px) {
      width: 40vw;
    }

    img {
      width: 40px;
      height: 40px;

      @media only screen and (max-width: 480px) {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
      @media screen and (max-width: 768px) and (min-width: 480px) {
        width: 45px;
        height: 45px;
        margin-right: 15px;
      }
      @media screen and (max-width: 1024px) and (min-width: 768px) {
        width: 35px;
        height: 35px;
        margin-right: 15px;
      }
      @media screen and (max-width: 1200px) and (min-width: 1024px) {
        width: 40px;
        height: 40px;
        margin-right: 15px;
      }
      @media screen and (min-width: 1200px) {
        width: 40px;
        height: 40px;
        margin-right: 15px;
      }
    }

    a {
      font-family: "Lora", serif;
      letter-spacing: 2px;
      font-size: 21px;
      color: #000000;
      font-weight: 900;
      text-decoration: none;

      @media only screen and (max-width: 480px) {
        font-weight: 900;
        font-size: 14px;
        letter-spacing: 1px;
      }
      @media screen and (max-width: 768px) and (min-width: 480px) {
        font-weight: 900;
        font-size: 16px;
        letter-spacing: 1px;
      }
      @media screen and (max-width: 1024px) and (min-width: 768px) {
        font-weight: 900;
        font-size: 18px;
        letter-spacing: 1px;
      }
      @media screen and (max-width: 1200px) and (min-width: 1024px) {
        font-weight: 900;
        font-size: 20px;
        letter-spacing: 1px;
      }
      @media screen and (min-width: 1200px) {
        font-weight: 900;
        font-size: 22px;
        letter-spacing: 1px;
      }
    }

    &:hover {
      box-shadow: 0 0 10px #fef4f5;
    }
  }
}

.copy {
  width: 100%;
  padding-top: 20px;
}

.link-text-1 {
  font-family: "Lora", serif;
  letter-spacing: 2px;
  font-size: 21px;
  color: #f47121;
  font-weight: 900;

  @media only screen and (max-width: 480px) {
    font-weight: 900;
    font-size: 11px;
    letter-spacing: 1px;
    text-align: right;
  }
  @media screen and (max-width: 768px) and (min-width: 480px) {
    font-weight: 900;
    font-size: 12px;
    letter-spacing: 1px;
    text-align: right;
  }
  @media screen and (max-width: 1024px) and (min-width: 768px) {
    font-weight: 900;
    font-size: 13px;
    letter-spacing: 1px;
    text-align: right;
  }
  @media screen and (max-width: 1200px) and (min-width: 1024px) {
    font-weight: 900;
    font-size: 14px;
    letter-spacing: 1px;
    text-align: right;
  }
  @media screen and (min-width: 1200px) {
    font-weight: 900;
    font-size: 15px;
    letter-spacing: 1px;
    text-align: right;
  }
}
.link-text-2 {
  font-family: "Lora", serif;
  letter-spacing: 2px;
  font-size: 21px;
  color: #f47121;
  font-weight: 900;

  @media only screen and (max-width: 480px) {
    font-weight: 900;
    font-size: 10px;
    letter-spacing: 1px;
    text-align: right;
  }
  @media screen and (max-width: 768px) and (min-width: 480px) {
    font-weight: 900;
    font-size: 11px;
    letter-spacing: 1px;
    text-align: right;
  }
  @media screen and (max-width: 1024px) and (min-width: 768px) {
    font-weight: 900;
    font-size: 12px;
    letter-spacing: 1px;
    text-align: right;
  }
  @media screen and (max-width: 1200px) and (min-width: 1024px) {
    font-weight: 900;
    font-size: 13px;
    letter-spacing: 1px;
    text-align: right;
  }
  @media screen and (min-width: 1200px) {
    font-weight: 900;
    font-size: 14px;
    letter-spacing: 1px;
    text-align: right;
  }
}
