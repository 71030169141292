.app__navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  // padding: 1rem 5rem;
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
  position: fixed;
  background: #ffffff;
  box-shadow: 0 4px 5px 0 rgba(197, 197, 197, 0.2),
    0 6px 20px 0 rgba(187, 187, 187, 0.19);
  box-shadow: 0 8px 18px 0 rgba(143, 143, 143, 0.37);
  backdrop-filter: blur(8px);
  border-bottom: #ffffff;
  z-index: 5;

  @media screen and (max-width: 480px) {
    padding-right: 2vw;
    padding-left: 2vw;
  }
  @media screen and (max-width: 768px) and (min-width: 480px) {
    padding-right: 2vw;
    padding-left: 2vw;
  }
  @media screen and (max-width: 1024px) and (min-width: 768px) {
    padding-right: 2vw;
    padding-left: 2vw;
  }
  @media screen and (max-width: 1200px) and (min-width: 1024px) {
    padding-right: 2vw;
    padding-left: 2vw;
  }
  @media screen and (min-width: 1024px) {
    padding-right: 2vw;
    padding-left: 2vw;
  }
}

.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 50%;
    height: 60%;

    @media only screen and (max-width: 480px) {
      width: 80%;
      height: 70%;
    }
    @media screen and (max-width: 768px) and (min-width: 480px) {
      width: 60%;
      height: 50%;
    }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
      width: 60%;
      height: 40%;
    }
    @media screen and (max-width: 1200px) and (min-width: 1024px) {
      width: 55%;
      height: 40%;
    }
    @media screen and (min-width: 1200px) {
      width: 58%;
      height: 20%;
    }
  }
}

.app__navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  list-style: none;

  li {
    margin: 0 1rem;
    cursor: pointer;

    flex-direction: column;

    div {
      width: 5px;
      height: 5px;
      background: transparent;
      border-radius: 50%;
      margin-bottom: 5px;
    }

    a {
      color: #000000;
      font-family: "Lora", serif;
      text-decoration: none;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 0.7px;
      font-weight: 700;
      flex-direction: column;

      transition: all 0.3s ease-in-out;

      @media screen and (max-width: 1024px) and (min-width: 768px) {
        font-size: 14px;
      }
      @media screen and (max-width: 1200px) and (min-width: 1024px) {
        font-size: 16px;
      }
      @media screen and (min-width: 1200px) {
        font-size: 18px;
      }

      &:hover {
        color: #f47121;
      }

      &:active {
        color: #f47121;
      }
    }

    &:hover {
      div {
        background: #f47121;
      }
    }
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.app__navbar-menu {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;

  @media only screen and (max-width: 480px) {
    width: 45px;
    height: 35px;
  }
  @media screen and (max-width: 768px) and (min-width: 480px) {
    width: 40px;
    height: 37px;
  }

  @media screen and (min-width: 769px) {
    display: none;
  }

  svg {
    width: 70%;
    height: 70%;
    color: #f47121;

    @media only screen and (max-width: 480px) {
      width: 35px;
    }
    @media screen and (max-width: 768px) and (min-width: 480px) {
      width: 37px;
    }
  }

  div {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 5;

    padding: 1rem;

    width: 70%;
    height: 100vh;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    //   background: url('../../assets/bgWhite.png');
    background-color: #000000;
    background-size: cover;
    background-repeat: repeat;

    /* top box shadow */
    box-shadow: 0px 0px 20px rgba(168, 168, 168, 0.15);

    svg {
      width: 35px;
      height: 35px;
      color: #f47121;
      margin: 0.5rem 1rem;

      @media only screen and (max-width: 480px) {
        width: 45px;
        height: 40px;
      }
      @media screen and (max-width: 768px) and (min-width: 480px) {
        width: 45px;
        height: 40px;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      li {
        margin: 1rem;

        a {
          color: #f47121;
          font-family: "Lora", serif;
          text-decoration: none;
          font-size: 16px;
          text-transform: uppercase;
          letter-spacing: 0.7px;
          font-weight: 500;

          transition: all 0.3s ease-in-out;

          &:hover {
            color: #f47121;
          }

          @media only screen and (max-width: 480px) {
            font-size: 18px;
            font-weight: 600;
          }
          @media screen and (max-width: 768px) and (min-width: 480px) {
            font-size: 19px;
            font-weight: 800;
            letter-spacing: 1px;
          }
        }
      }
    }
  }
}
